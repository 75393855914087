import SecureLS from 'secure-ls';
import { getToken } from '../../contexts/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { AuthUrls } from './urls';

const ls = new SecureLS({ encodingType: 'aes' });

class BaseService {
  static getHeaders = (isFile?: boolean) => {
    const headers = new Headers();
    if (!isFile) {
      headers.append('Content-Type', 'application/json');
    }
    headers.append('Accept', 'application/json');
    headers.append('Origin', '*');
    const lang = ls.get('lang') || 'fr';
    headers.append('Accept-Language', lang);
    return headers;
  };

  static logoutUser = () => {
    const event = new CustomEvent('onTokenExpired', {
      bubbles: true,
      cancelable: true,
    });
    window.dispatchEvent(event);
  };

  static renewToken = () => {
    const pending = localStorage.getItem('renew-token-status');
    if (pending) {
      return;
    }
    localStorage.setItem('renew-token-status', 'true');
    BaseService.getRequest(AuthUrls.REFRESH_TOKEN, true)
      .then(async (res: any) => {
        if (res.status === 200 || res.status === 201) {
          const data = await res.json();
          console.log('data', data);
          ls.set('token', data.jwt);
        }
      })
      .catch((e) => {
        console.log('error', e);
      })
      .finally(() => {
        localStorage.removeItem('renew-token-status');
      });
  };

  static checkToken = () => {
    const token = getToken();
    const decoded = jwtDecode(token);
    const renewDelay = 900; // last 15 minutes
    // const renewDelay = 3540; // first minute for test
    const diff = (decoded.exp || 0) - Date.now() / 1000;

    if (!token || diff < 0) {
      // on met un timer pour être sur l'événement soit bien pris en compte par le composant React
      setTimeout(() => {
        BaseService.logoutUser();
      }, 2000);
    } else if (diff < renewDelay) {
      BaseService.renewToken();
    }
  };

  static getHeadersAuth = (isFile?: boolean) => {
    const headers = BaseService.getHeaders(isFile);
    const token = getToken();
    BaseService.checkToken();
    headers.append('Authorization', `Bearer ${token}`);
    return headers;
  };

  static postRequest = async (
    url: string,
    body: object,
    requiredAuth: boolean,
  ) => {
    const head = requiredAuth
      ? BaseService.getHeadersAuth()
      : BaseService.getHeaders();

    const headers = {
      method: 'POST',
      headers: head,
      mode: 'cors' as const,
      cache: 'default' as const,
      body: JSON.stringify(body),
    };

    return await fetch(url, headers)
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  static postFileRequest = async (
    url: string,
    body: FormData,
    requiredAuth: boolean,
  ) => {
    const head = requiredAuth
      ? BaseService.getHeadersAuth(true)
      : BaseService.getHeaders(true);

    const headers = {
      method: 'POST',
      headers: head,
      mode: 'cors' as const,
      cache: 'default' as const,
      body,
    };
    return await fetch(url, headers)
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  static putFileRequest = async (
    url: string,
    body: FormData,
    requiredAuth: boolean,
  ) => {
    const head = requiredAuth
      ? BaseService.getHeadersAuth(true)
      : BaseService.getHeaders(true);

    const headers = {
      method: 'PUT',
      headers: head,
      mode: 'cors' as const,
      cache: 'default' as const,
      body,
    };
    return await fetch(url, headers)
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  static putRequest = async (
    url: string,
    body: object,
    requiredAuth: boolean,
  ) => {
    const head = requiredAuth
      ? BaseService.getHeadersAuth()
      : BaseService.getHeaders();

    const headers = {
      method: 'PUT',
      headers: head,
      mode: 'cors' as const,
      cache: 'default' as const,
      body: JSON.stringify(body),
    };
    return await fetch(url, headers)
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  static patchRequest = async (
    url: string,
    body: object,
    requiredAuth: boolean,
  ) => {
    const head = requiredAuth
      ? BaseService.getHeadersAuth()
      : BaseService.getHeaders();

    const headers = {
      method: 'PATCH',
      headers: head,
      mode: 'cors' as const,
      cache: 'default' as const,
      body: JSON.stringify(body),
    };
    return await fetch(url, headers)
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  static deleteRequest = async (
    url: string,
    body: object,
    requiredAuth: boolean,
  ) => {
    const head = requiredAuth
      ? BaseService.getHeadersAuth()
      : BaseService.getHeaders();

    const headers = {
      method: 'DELETE',
      headers: head,
      mode: 'cors' as const,
      cache: 'default' as const,
      body: JSON.stringify(body),
    };
    return await fetch(url, headers)
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  static getRequest = async (url: string, requiredAuth: boolean) => {
    const head = requiredAuth
      ? BaseService.getHeadersAuth()
      : BaseService.getHeaders();

    const headers = {
      method: 'GET',
      headers: head,
      mode: 'cors' as const,
      cache: 'default' as const,
    };
    return await fetch(url, headers)
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };
}

export interface requestInterface {
  url?: string;
  body?: object;
  requiredAuth?: boolean;
}

export default BaseService;
