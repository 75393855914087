import * as React from 'react';
import Routes from './routes';
import 'translations';
import { ScrollToTop } from 'components';
import { ConfigProvider } from 'antd';
import appTheme from './theme/theme';
import { AuthContextProvider } from './contexts/AuthContext';

export default function App() {
  return (
    <AuthContextProvider>
      <ConfigProvider {...appTheme}>
        <ScrollToTop />
        <Routes />
      </ConfigProvider>
    </AuthContextProvider>
  );
}
