import React, { useState, useMemo, useContext, useEffect } from 'react';
import SecureLS from 'secure-ls';
import { User } from 'types/User';
import AuthService from '../services/api/AuthService';

const ls = new SecureLS({ encodingType: 'aes' });

type AuthPayload = {
  token: string;
  user: User;
};

export interface AuthContextType {
  auth?: AuthPayload;
  login: (auth: AuthPayload) => void;
  logout: () => void;
}

export const AuthContext = React.createContext<AuthContextType>({
  auth: undefined,
  login: () => {},
  logout: () => {},
});

interface AuthContextProviderProps {
  children: React.ReactNode;
}

const getUser = () => {
  try {
    return ls.get('user') ? ls.get('user') : undefined;
  } catch (error) {
    return undefined;
  }
};

export const getToken = () => {
  try {
    return ls.get('token') ? ls.get('token') : undefined;
  } catch (error) {
    return undefined;
  }
};

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const user = useMemo(getUser, []);

  const token = useMemo(getToken, []);

  const initialAuth = user && token ? { user, token } : undefined;

  const [auth, setAuth] = useState<AuthContextType['auth']>(initialAuth);

  const login: AuthContextType['login'] = (auth) => {
    setAuth(auth);
    ls.set('token', auth?.token);
    ls.set('user', auth?.user);
  };

  const logout = () => {
    setAuth(undefined);
    ls.remove('token');
    ls.remove('user');
  };

  const refreshCurrentUser = () => {
    AuthService.get_userInfo()
      .then(async (res: any) => {
        if (res.status === 200 || res.status === 201) {
          const user = await res.json();
          login({
            user,
            token,
          });
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (token) {
      refreshCurrentUser();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
