import React, { useEffect } from 'react';
import {
  Avatar,
  Button,
  Flex,
  Layout,
  Menu,
  notification,
  theme,
  Typography,
} from 'antd';

import { ReactComponent as WhiteLogo } from 'assets/icons/WhiteLogo.svg';
import { ReactComponent as Person } from 'assets/icons/Person.svg';
import { useAuthContext } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { useHistory, matchPath } from 'react-router';
import navigationConfig, { MenuItem } from '../routes/navigationConfig';
import { routesConfig } from '../routes';
import BaseService from '../services/api/BaseService';

const { Content, Sider } = Layout;

const AuthLayout = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { auth, logout } = useAuthContext();
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const history = useHistory();
  const pathname = history.location.pathname;

  const handleLogout = () => {
    logout();
    history.push('/connexion');
  };

  const handleMenuClick = (e: MenuItem) => {
    if (typeof e?.key === 'string') history.push(e.key);
  };

  const visibilityChange = () => {
    if (document.visibilityState === 'visible' || !document.hidden) {
      BaseService.checkToken();
    }
  };

  const handleTokenExpired = () => {
    handleLogout();
    notification.info({
      message: t('global:session_expired'),
    });
  };

  useEffect(() => {
    window.addEventListener('onTokenExpired', handleTokenExpired);
    document.addEventListener('visibilitychange', visibilityChange);
    return () => {
      window.removeEventListener('onTokenExpired', handleTokenExpired);
      document.removeEventListener('visibilitychange', visibilityChange);
    };
  }, []);

  const items = navigationConfig(handleMenuClick);

  const defaultSelectedKeys =
    routesConfig.find((item) =>
      matchPath(pathname, { path: item.path, exact: item.exact }),
    )?.path ?? '/';

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        className={'slider-override'}
        width={240}
        style={{
          display: 'flex',
        }}
      >
        <Flex
          justify={'center'}
          style={{
            padding: '20px 0',
          }}
        >
          <WhiteLogo />
        </Flex>
        <Menu
          theme={'dark'}
          selectedKeys={[`${defaultSelectedKeys}`]}
          mode="inline"
          items={items}
        />
        <div style={{ flexGrow: 1 }} />
        <Flex
          align={'center'}
          gap={8}
          style={{
            paddingLeft: 32,
            paddingBottom: 8,
          }}
        >
          <Avatar
            style={{
              minWidth: 32,
              backgroundColor: token.colorPrimaryText,
            }}
            icon={<Person />}
          />
          <Typography.Text
            ellipsis
            style={{
              color: token.colorPrimaryText,
            }}
          >
            {auth?.user.firstname} {auth?.user.lastname}
          </Typography.Text>
        </Flex>
        <Button onClick={handleLogout} type={'primary'} className={'logout'}>
          {t('global:logout')}
        </Button>
      </Sider>
      <Layout>
        <Content style={{ margin: '0 16px' }}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default React.memo(AuthLayout);
